<!--
 * @Author: lsw lsw_0524@163.com
 * @Date: 2024-03-12 15:34:49
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-03-12 15:35:11
 * @FilePath: /yiyun_project/src/views/shortVideo/marketingManagement/vipOrder.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main></el-main>
</template>

<script>
export default {
  data() {
    return {
      order_list: {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      return;
      let obj = {
        page: this.order_list.page,
        rows: this.order_list.rows,
      };
      this.$axios.post(this.$api.shortVideo.vipCardList, obj).then(res => {
        if (res.code == 0) {
          this.order_list.list = res.result.list;
          console.log(this.order_list.list);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
